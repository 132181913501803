import React from 'react'
import './Navbar.css'

function Navbar() {
    return (
        <div className={"navbar"}>
            <div className={"navbar-text"}>Mandi Market</div>
        </div>
    )
}

export default Navbar
